<template>
  <div
    class="app-credentials-form"
    data-testid="app-credentials-form"
  >
    <KToggle
      v-slot="{isToggled, toggle}"
      :toggled="edit"
    >
      <div class="app-credentials-header">
        <KLabel>{{ t('labels.credential', 1) }}</KLabel>

        <PortalKButton
          appearance="tertiary"
          :class="isToggled.value ? 'clear' : 'add'"
          data-testid="app-credentials-toggle-button"
          @click="onToggle(toggle, isToggled.value)"
        >
          {{ isToggled.value ? t('actions.clear_name') : t('actions.add_custom_name') }}
        </PortalKButton>
      </div>

      <KInput
        v-if="isToggled.value"
        v-model.trim="name"
        autocomplete="off"
        class="app-credentials-input"
        data-testid="app-credentials-input"
        :placeholder="t('app_credentials.name_placeholder')"
      />
    </KToggle>

    <template v-if="credentials && 'credential' in credentials">
      <KInput
        class="app-credentials-input app-credentials-input-credential"
        data-testid="app-credentials-input-credential"
        :model-value="credentials?.credential || ''"
        readonly
      >
        <template #after>
          <CopyButton
            data-testid="app-credentials-copy-button"
            :text="credentials?.credential || ''"
          />
        </template>
      </KInput>

      <p class="app-credentials-warning">
        {{ t('app_credentials.warning') }}
      </p>
    </template>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()

const props = defineProps({
  credentials: {
    type: [Object, null] as PropType<PortalApiResponseTemp<'create-credential'> | PortalApiResponseTemp<'list-credentials'>['data'][0] | null>,
    required: true,
  },
  edit: {
    type: Boolean,
    default: false,
  },
})

const name = defineModel('name', { type: String, required: true })

watch(() => props.credentials?.display_name, (savedName => {
  if (savedName && savedName !== props.credentials?.id) {
    name.value = savedName
  }
}), {
  immediate: true,
})

const onToggle = (callback: () => void, isToggled: boolean): void => {
  if (isToggled) {
    name.value = ''
  }

  callback()
}
</script>

<style scoped lang="scss">
.app-credentials {
  &-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--kui-space-40, $kui-space-40);

    :deep(.k-label) {
      margin-bottom: var(--kui-space-0, $kui-space-0);
    }
  }

  &-input {
    margin-bottom: var(--kui-space-40, $kui-space-40);

    &-credential {
      :deep(.input) {
        font-family: var(--kui-font-family-code, $kui-font-family-code);
      }
    }
  }

  &-warning {
    color: var(--kui-color-text-neutral, $kui-color-text-neutral);
    font-size: var(--kui-font-size-20, $kui-font-size-20);
    line-height: var(--kui-line-height-20, $kui-line-height-20);
  }
}
</style>
