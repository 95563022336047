import { randomUUID } from 'uncrypto'

export default function useCredentialsCRUD(id: string) {
  const { $portalApi } = useNuxtApp()
  const { t } = useI18n()

  // Create credentials
  const credentials = useState<PortalApiResponseTemp<'create-credential'> | null>(() => null)
  const credentialsError = useState<Record <string, any> | null>(`credentials-error-${id}`, () => null)

  /**
   * Creates new credentials for the application
   * @param {string} [applicationId] - Application ID to create credentials.
   * @param {string} [applicationName] - Application name to generate a default credentials' display name.
   * @returns {Promise<void>}
   */
  const createCredentials = async (applicationId: string, applicationName: string): Promise<void> => {
    try {
      if (applicationId) {
        credentials.value = await $portalApi(
          '/api/v3/applications/{applicationId}/credentials', {
            path: {
              applicationId,
            },
            method: 'POST',
            body: {
              // generate default name
              display_name: `${applicationName}-${randomUUID()}-key`,
            },
          })
      } else {
        credentialsError.value = createError({ data:  { detail: t('errors.general.param_should_not_be_empty', { param: 'applicationId' }) } })
      }
    } catch (error: any) {
      credentialsError.value = error
    }
  }

  // Update credentials
  const credentialsName = useState<string>(() => '')

  /**
   * Updates credentials for the application
   * @param {string} [applicationId] - Application ID to update credentials.
   * @param {string} [credentialId] - Credential ID to update credentials.
   * @returns {Promise<void>}
   */
  const updateCredentials = async (applicationId: string, credentialId: string) => {
    try {
      if (applicationId && credentialId) {
        await $portalApi(
          '/api/v3/applications/{applicationId}/credentials/{credentialId}', {
            path: {
              applicationId,
              credentialId,
            },
            method: 'PUT',
            body: {
              display_name: credentialsName.value,
            },
          })
      } else {
        credentialsError.value = createError({ data:  { detail: t('errors.general.param_should_not_be_empty', { param: 'applicationId or credentialId' }) } })
      }
    } catch (error: any) {
      credentialsError.value = error
    }
  }
  /**
   * Removes credentials for the application
   * @param {string} [applicationId] - Application ID to update credentials.
   * @param {string} [credentialId] - Credential ID to update credentials.
   * @returns {Promise<void>}
   */
  const deleteCredentials = async (applicationId: string, credentialId: string) => {
    try {
      if (applicationId && credentialId) {
        await $portalApi(
          '/api/v3/applications/{applicationId}/credentials/{credentialId}', {
            path: {
              applicationId,
              credentialId,
            },
            method: 'DELETE',
          })
      } else {
        credentialsError.value = createError({ data:  { detail: t('errors.general.param_should_not_be_empty', { param: 'applicationId or credentialId' }) } })
      }
    } catch (error: any) {
      credentialsError.value = error
    }
  }

  return {
    createCredentials,
    credentials,
    credentialsError,
    credentialsName,
    updateCredentials,
    deleteCredentials,
  }
}
