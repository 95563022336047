<template>
  <EmptyState
    :description="description || t('apps.list.welcome_description')"
    :title="title || t('apps.list.welcome')"
  >
    <template #icon>
      <WidgetsIcon decorative />
    </template>
    <template #action>
      <PortalKButton
        :disabled="loading"
        @click="$emit('click:action')"
      >
        <ProgressIcon v-if="loading" /> {{ actionButtonText || t('actions.create_app') }}
      </PortalKButton>
    </template>

    <PageContainer
      class="empty-info-list"
      display="grid"
      :grid-columns-breakpoints="{ phablet: 1, tablet: 3 }"
      tag="ul"
    >
      <AppsEmptyStateItem
        v-for="infoItem in info"
        :key="infoItem.title"
        :info="infoItem"
      />
    </PageContainer>
  </EmptyState>
</template>

<script setup lang="ts">
import { WidgetsIcon, PortalIcon, LockIcon, ProfileTeamIcon, ProgressIcon } from '@kong/icons'

defineProps({
  title: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  actionButtonText: {
    type: String,
    default: '',
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

defineEmits<{
  (e: 'click:action'): void
}>()

const { t } = useI18n()
const info = useState<AppsEmptyStateInfo[]>(() => shallowRef([
  {
    icon: PortalIcon,
    title: t('apps.list.info_register_title'),
    description: t('apps.list.info_register_description'),
  },
  {
    icon: LockIcon,
    title: t('apps.list.info_credentials_title'),
    description: t('apps.list.info_credentials_description'),
  },
  {
    icon: ProfileTeamIcon,
    title: t('apps.list.info_strategies_title'),
    description: t('apps.list.info_strategies_description'),
  },
]))
</script>

<style scoped lang="scss">
.empty-info-list {
  list-style: none;
  margin: var(--kui-space-70, $kui-space-70) var(--kui-space-0, $kui-space-0);
  padding-left: var(--kui-space-0, $kui-space-0);
}
</style>
